import React, { useEffect, useState, lazy } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from './utils/Loading';
//import Main from './components/main';
const Main = lazy(() => import('./components/main'));
const config = {
  tokenUrl: process.env.REACT_APP_TOKEN_ENDPOINT,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  userInfoUri: `${process.env.REACT_APP_USERINFO_ENDPOINT}`,
};

const getCodeVerifierFromLocalStorage = (): string => {
  var test = localStorage.getItem("CodeVerifier");
  return test;
}


const Callback: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [coveotoken, setCoveoToken] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<{ name?: string; initials?: string; email?: string } | null>(null);
 // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchToken = async (code) => {
    const codeVerifier = getCodeVerifierFromLocalStorage();

    return fetch(config.tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
       // 'Access-Control-Allow-Origin': '*',
      },
      body: new URLSearchParams({
        code,
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        code_verifier: codeVerifier,
        grant_type: 'authorization_code',
      }).toString(),
    });
  };

  useEffect(() => {
    const initiateTokenFlow = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const codes = urlParams.get('code');
   //   setCode(codes);
      // If code is not present, initiate the authentication flow
      try {
        // Make the token request
        if (!token) {
          const response = await fetchToken(codes);
          if (response.ok) {
            const data = await response.json();
            setToken(data.access_token);
            setRefreshToken(data.refresh_token);
           // let token = data.access_token;

            const userResponse = await fetch(config.userInfoUri, {
              headers: {
                Authorization: `Bearer ${data.access_token}`,
              },
            });

            if (userResponse.ok) {
              const user = await userResponse.json();
              localStorage.setItem("useremail", user.email);
              setUserDetails({
                name: user.family_name + ', ' + user.given_name,
                initials: user.family_name.charAt(0) + user.given_name.charAt(0),
                email: user.email,
              });
              fetchCoveoToken(user.email);

            //  setLoading(false);
              //  navigate('/main');
            } else {
              console.error('Error fetching user details:', userResponse.statusText);
            //  setLoading(false);
            }
            //localStorage.clear()
            //clearCodeVerifierFromLocalStorage(); // Clear the code verifier after use

            // navigate('/main');

            // if (refreshToken) {
            //   const refreshRequestData = {
            //     grant_type: 'refresh_token',
            //     refreshToken,
            //     client_id: config.clientId,
            //   };

            //   const refreshResponse = await axios.post(config.tokenUrl, refreshRequestData);

            //   if (refreshResponse.status === 200) {
            //     const newAccessToken = refreshResponse.data.access_token;
            //     // Use the new access token as needed
            //     setToken(newAccessToken);
            //   }
            // }

          }
          else {
            navigate('/');
            console.error('Error fetching token:');
          }
        }
      } catch (error) {
        navigate('/');
        console.error('Error fetching token:', error);
      }
    };
    initiateTokenFlow();

  }, [coveotoken]);

  async function fetchCoveoToken(email) {
    return new Promise<string>(async (resolve, reject) => {
      try {
  
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_COVEO_API_KEY);
        //const email=localStorage.getItem('useremail')? localStorage.getItem('useremail'):"";
  
       
        var raw = JSON.stringify({
          "userIds": [
            {
              "name": email,
              "type": "User",
              "provider": "Email Security Provider",
              "infos": {},
              "authCookie": ""
            }
          ]
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
    
  
    
       await fetch(process.env.REACT_APP_COVEO_TOKEN_URL+"="+process.env.REACT_APP_COVEO_ORGANIZATION_ID, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            //setToken(data);
            const newAccessToken = String(data.token);
            if(newAccessToken!=="undefined")
            {
            localStorage.setItem('coveotoken', newAccessToken);
            setCoveoToken(newAccessToken);
            }
            resolve(newAccessToken as string);
  
          })
          .catch((error) => {
            reject(`Failed to parse response data: ${error}`);
          });
    
      
      } catch (error) {
        reject(`Failed to renew access token. Status:`);
        throw error;
      }
    });
  }


  
  return (

    <div>
      {/* {loading ? (
         <LoadingSpinner/>
      ) : ( */}
       { token && userDetails && coveotoken ? <Main userDetails={userDetails} /> :

          <LoadingSpinner/>
    }
    </div>
 
  );
};

export default Callback;
